<template>
  <ul class="bottom-menu">
    <li v-for="(item, index) in menuList" :key="index" @click="onItemClick(item)">{{ item.title }}</li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BottomMenu',
  computed: {
    ...mapGetters(['menuList'])
  },
  methods: {
    onItemClick(item) {
      let curPath = this.$route.path
      let path = item.path
      if (curPath === path) return
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
.bottom-menu {
  text-align: center;

  li {
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    list-style-type: none;
    display: inline-block;
    margin-left: 40px;
    line-height: 40px;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: #6aaf08;
    }
  }
}
</style>
