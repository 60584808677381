<template>
  <div>
    <top-menu></top-menu>
    <div class="top-bottom box-margin xs-hide">
      <img src="/img/logo.png" alt="" class="logo" />
      <img src="/img//top/logo-desc.png" alt="" class="logo-desc" />
      <div class="wx-code">
        <img src="/img/logo.png" alt="" />
        <div class="wx-code-desc">微信小程序</div>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from '@/components/Top/TopMenu.vue'

export default {
  name: 'index',
  components: { TopMenu }
}
</script>

<style scoped lang="scss">
.top-bottom {
  height: 160px;
  padding-top: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 252px;
    height: 100px;
  }

  .logo-desc {
    width: 339px;
    height: 34px;
  }

  .wx-code {
    img {
      width: 98px;
      height: 98px;
    }

    &-desc {
      width: 98px;
      text-align: center;
      font-size: 18px;
    }
  }
}
</style>
