import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import config from '@/config'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: {
          title: '首页'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/home')
      },
      {
        path: '/join',
        name: 'Join',
        meta: {
          title: '团长加盟'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/join')
      },

      {
        path: '/company',
        name: 'Company',
        meta: {
          title: '公司历程'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/company')
      },
      {
        path: '/product',
        name: 'Product',
        meta: {
          title: '产品中心'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/product')
      },
      {
        path: '/supplier',
        name: 'Supplier',
        meta: {
          title: '供应链招商'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/supplier')
      },
      {
        path: '/about',
        name: 'About',
        meta: {
          title: '关于我们'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/about')
      }
    ]
  }
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta && to.meta.title)
  next()
})

export default router

function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${config.title}`
  }
  return `${config.title}`
}
