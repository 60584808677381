import Vue from "vue";
import Vuex from "vuex";

import common from '@/store/modules/common'
import getters from "@/store/getters";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters,
    mutations: {},
    actions: {},
    modules: {common},
});
