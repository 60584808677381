<template>
  <div class="layout">
    <top></top>
    <router-view />
    <bottom></bottom>
  </div>
</template>

<script>
import top from '@/components/Top/index.vue'
import bottom from '@/components/Bottom/index.vue'

export default {
  components: { top, bottom },
  name: 'layout'
}
</script>

<style scoped lang="scss">
.layout {
  max-width: 1920px;
  //min-width: 1200px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}
</style>
