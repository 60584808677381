<template>
  <div class="layout-bottom">
    <div class="container-top box-margin">
      <el-row>
        <el-col :lg="15">
          <div class="container-top-left">
            <div class="menu xs-hide">
              <bottom-menu></bottom-menu>
            </div>
            <div class="content">
              <div class="content-desc">
                <div class="content-desc-title">关于我们</div>
                <div class="content-desc-content">
                  田里今香原生态农产品社区团购平台主营原生态农产品、自然养殖肉禽蛋、无公害果蔬、干货等，是专业化的农产品在线交易综合服务性平台，
                </div>
              </div>
              <div class="content-service">
                <img src="/img/service-code.png" alt="二维码" class="content-service-code" />
                <div class="content-service-desc">添加客服微信</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :lg="9">
          <div class="container-top-right">
            <div class="contact"><img src="/img/bottom/contact.png" alt="" /></div>
            <div class="contact-zh">联系我们</div>
            <div class="phone">
              <div>
                <i class="phone-icon m-icon-contact-phone iconfont"></i>
              </div>
              <div>
                <div class="phone-desc">联系电话</div>
                <div class="phone-num">189-6027-5365</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="container-bottom">@福建田田香农业科技有限公司  &nbsp;&nbsp; <a class="container-bottom" href="https://beian.miit.gov.cn">闽ICP备2023024023号-1</a></div>
  </div>
</template>

<script>
import config from '@/config'
import bottomMenu from '@/components/Bottom/BottomMenu.vue'

export default {
  name: 'bottom',
  components: { bottomMenu },
  computed: {
    config() {
      return config
    }
  }
}
</script>

<style scoped lang="scss">
.layout-bottom {
  background: #191919;

  .container-top {
    box-sizing: border-box;
    background: #191919;
    padding-bottom: 30px;

    &-left {
      width: 100%;
      padding-top: 83px;
      .menu {
        border-bottom: 1px solid #4c822a;
        height: 40px;
      }

      .content {
        display: flex;
        justify-content: space-between;

        &-desc {
          margin-top: 20px;
          margin-right: 10px;
          width: 418px;

          &-title {
            line-height: 1;
            color: #4c822a;
            font-size: 26px;
          }

          &-content {
            margin-top: 34px;
            font-size: 18px;
            font-weight: 400;
            color: #c1c1c1;
          }
        }

        &-service {
          padding-top: 20px;
          padding-right: 20px;
          &-code {
            width: 120px;
            height: 120px;
          }

          &-desc {
            margin-top: 10px;
            width: 120px;
            text-align: center;
            font-weight: 400;
            color: #c1c1c1;
          }
        }
      }
    }

    &-right {
      width: 100%;
      color: #ffffff;
      padding-top: 83px;
      .contact {
        img {
          margin-left: auto;
          height: 50px;
          width: 392px;
        }
      }

      .contact-zh {
        text-align: right;
        margin-top: 22px;
        font-size: 26px;
        font-weight: 400;
      }

      .phone {
        margin-left: 60px;
        &-icon {
          font-size: 55px;
          margin-right: 15px;
        }

        display: flex;
        margin-top: 20px;
        line-height: 1;

        &-desc {
          font-size: 26px;
        }

        &-num {
          margin-top: 10px;
          font-size: 26px;
          font-weight: 400;
        }
      }
    }
  }

  .container-bottom {
    height: 60px;
    background: #000;
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 60px;
    text-align: center;
  }
}
</style>
