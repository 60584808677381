<template>
  <div class="top-menu-wrapper">
    <div class="top-menu box-margin">
      <ul class="xs-hide">
        <li
          v-for="(item, index) in menuList"
          :key="index"
          @click="onItemClick(item)"
          :class="{ active: item.path === $route.path }"
        >
          {{ item.title }}
        </li>
      </ul>
      <i class="menu-icon m-icon-menu iconfont xs-show" @click="mobileMenu = !mobileMenu"></i>
    </div>
    <div class="top-menu-mobile xs-show" v-if="mobileMenu">
      <ul class="">
        <li
          v-for="(item, index) in menuList"
          :key="index"
          @click="onItemClick(item)"
          :class="{ active: item.path === $route.path }"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  data() {
    return {
      mobileMenu: false
    }
  },
  methods: {
    onItemClick(item) {
      let curPath = this.$route.path
      let path = item.path
      if (curPath === path) return
      this.mobileMenu = false
      this.$router.push(path)
    }
  },
  computed: {
    ...mapGetters(['menuList'])
  }
}
</script>

<style scoped lang="scss">
.top-menu-wrapper {
  //width: 100%;
  background-color: $base-color-primary;
  height: 76px;
  position: relative;
}

.top-menu-mobile {
  display: none;

  position: relative;
  z-index: 9999;
  font-size: 18px;

  ul {
    background-color: $base-color-primary;

    li {
      padding: 15px 20px;
      color: #fff;
      font-weight: 400;
      border-bottom: 1px #ffffff solid;
    }
  }
}

.top-menu {
  background-color: $base-color-primary;
  //width: 100%;
  //position: absolute;
  top: 0;
  height: 70px;
  left: 0;
  padding: 23px;
  box-sizing: border-box;

  .menu-icon {
    color: #fff;
    display: none;
    font-size: 20px;
  }

  ul {
    text-align: center;
    display: flex;
    justify-content: space-evenly;

    li {
      cursor: pointer;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      list-style-type: none;
      display: inline-block;

      &:hover {
        color: #6aaf08;
      }
    }

    .active {
      color: #6aaf08;
    }
  }
}
</style>
